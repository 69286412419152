<template>
  <div>
    <vue-good-table :columns="columns"
                    :rows="rows"
                    :pagination-options="{ enabled: true }"
                    :sort-options="{
                      enabled: true,
                      initialSortBy: [{
                        field: 'submittedOn',
                        type: 'desc'
                      },{
                        field: 'id',
                        type: 'desc'
                      }]
                    } " >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'edit'">
          <button @click="editBrandStory(props.row.id)">edit</button>
        </span>
        <span v-else-if="props.column.field == 'submittedBy'">
          <router-link
            @click.native="setProfileViewUser(props.row.submittedByUser)"
            :to="{path: 'profile', query: { id: props.row.submittedByUser.id }}">
            {{props.formattedRow[props.column.field]}}
          </router-link>
        </span>
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table';
import BrandStorySubmissionAdmin from './BrandStorySubmissionAdmin.vue';

export default {
  name: 'BrandStoryAdmin',
  components: {
    VueGoodTable,
  },
  data() {
    return {
      rows: [],
    };
  },
  computed: {
    statuses() {
      return this.$store.getters['brandStoryStatusStore/statuses'];
    },
    columns() {
      return [
        {
          label: 'Id',
          field: 'id',
          hidden: true,
        },
        {
          label: 'Date Submitted',
          field: 'submittedOn',
          sortable: true,
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'yyyy-MM-dd',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Submitted By',
          field: 'submittedBy',
          sortable: true,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Business Area(s)',
          field: 'businessAreas',
          sortable: true,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Tenet(s)',
          field: 'effortlessTenets',
          sortable: true,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Title',
          field: 'subject',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Attachments',
          field: 'attachments',
          type: 'number',
        },
        {
          label: 'Thumbnail Image',
          field: 'thunbnail',
        },
        {
          label: 'Status',
          field: 'status',
          sortable: true,
          filterOptions: {
            enabled: true,
            filterDropdownItems: this.statuses.map(status => status.text),
          },
        },
        {
          label: 'Edit',
          field: 'edit',
        },
      ];
    },
  },
  methods: {
    async getBrandStories() {
      const response = await this.$store.dispatch('adminStore/getBrandStories');

      this.rows = response.map(this.toRowObject);
    },
    onBrandStoryEdited(story) {
      this.rows = this.rows.filter(row => row.id !== story.id);
      this.rows.push(this.toRowObject(story));
    },
    toRowObject(story) {
      const toDateString = (date) => {
        const pad = number => (number.length === 1
          ? `0${number}`
          : number);

        const temp = new Date(date);

        return `${temp.getFullYear()}-${pad(temp.getMonth() + 1)}-${pad(temp.getDate())}`;
      };
      return {
        id: story.id,
        submittedOn: toDateString(story.submittedOn),
        submittedBy: story.submittedByUser ? `${story.submittedByUser.nameFirst} ${story.submittedByUser.nameLast}` : null,
        submittedByUser: story.submittedByUser,
        businessAreas: story.businessAreas.map(area => area.name).join(', '),
        effortlessTenets: story.effortlessTenets.map(tenet => tenet.name).join(', '),
        subject: story.subject,
        status: this.statuses.filter(status => status.value === story.status)[0].text,
        attachments: (story.attachments.length === 0) ? null : story.attachments.length,
      };
    },
    async editBrandStory(id) {
      const name = 'BrandStorySubmissionAdmin';

      const response = await this.$store.dispatch('adminStore/getBrandStory', id);
      this.$modal.show(BrandStorySubmissionAdmin, {
        story: response,
        name,
        onBrandStoryEdited: this.onBrandStoryEdited,
      },
      {
        name,
        adaptive: true,
        height: 'auto',
        width: '70%',
        scrollable: true,
      });
    },
    setProfileViewUser(submittedByUser) {
      const route = this.$route;
      if (route.name !== 'profile' || route.query.id !== submittedByUser.id) {
        this.$store.dispatch(
          'profileViewStore/setProfile',
          submittedByUser,
        );
      }
    },
  },
  async mounted() {
    await this.getBrandStories();
  },
  async created() {
    await this.$store.dispatch('brandStoryStatusStore/getBrandStoryStatuses');
  },
};
</script>

<style lang="scss" scoped>
</style>
