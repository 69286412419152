var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-good-table", {
        attrs: {
          columns: _vm.columns,
          rows: _vm.rows,
          "pagination-options": { enabled: true },
          "sort-options": {
            enabled: true,
            initialSortBy: [
              {
                field: "submittedOn",
                type: "desc",
              },
              {
                field: "id",
                type: "desc",
              },
            ],
          },
        },
        scopedSlots: _vm._u([
          {
            key: "table-row",
            fn: function (props) {
              return [
                props.column.field == "edit"
                  ? _c("span", [
                      _c(
                        "button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.editWhpcStory(props.row.id)
                            },
                          },
                        },
                        [_vm._v("edit")]
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        "\n        " +
                          _vm._s(props.formattedRow[props.column.field]) +
                          "\n      "
                      ),
                    ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }