<template>
  <form @submit.prevent="submit">
    <div class="commentSubmissionAdmin">
      <span class="label">Comment</span>
      <textarea class="textBox"
                v-model="commentUnderReview.text"
                type="text" />
      <label class="status">
        <div class="label">Status</div>
        <div class="control">
          <select v-model="commentUnderReview.status">
            <option v-for="status in statuses" :value="status.value" :key="status.value">
              {{status.text}}
            </option>
          </select>
        </div>
      </label>
      <br />
      <ButtonSubmit
        :copy="'Save'"
        @callback="submit"
      />
      <ButtonCancel class="cancel" @click="closeModal" />
    </div>
  </form>
</template>
<script>
import ButtonCancel from '../formElements/buttons/ButtonCancel.vue';
import ButtonSubmit from '../formElements/buttons/buttonSubmit.vue';

export default {
  name: 'commentSubmissionAdmin',
  props: {
    name: {
      type: String,
      required: true,
    },
    comment: {
      type: Object,
      required: true,
    },
    onCommentEdited: {
      type: Function,
      default: () => { },
    },
  },
  components: {
    ButtonSubmit,
    ButtonCancel,
  },
  data() {
    return {
      commentUnderReview: this.comment,
    };
  },
  computed: {
    submittedOn() {
      return new Date(this.commentUnderReview.submittedOn).toLocaleDateString();
    },
    statuses() {
      return this.$store.getters['commentStatusStore/statuses'];
    },
  },
  methods: {
    updateBody(html) {
      this.commentUnderReview.body = html;
    },
    async submit() {
      const brandStoryComment = this.commentUnderReview;
      delete brandStoryComment.brandStory.thumbnailAsset;
      await this.$store.dispatch('adminStore/updateCommentforBrandStory', brandStoryComment);
      this.onCommentEdited(brandStoryComment);
      this.closeModal();
      this.$store.dispatch('buttonSubmitStore/done');
    },
    closeModal() {
      this.$modal.hide(this.name);
    },
  },
};
</script>
<style lang="scss" scoped>
  .commentSubmissionAdmin {
    padding: 2em;
    background-color: white;
  }

  span {
    display: none;
  }

  .textBox {
    height: 150px;
    width: 100%;
    margin: 2em 0;
    display: block;
    border: 1px solid $LincolnGrayDark;
  }

  .status {
    display: flex;
    align-items: center;
    background: white;
    color: $LincolnGrayDark;
    border: 1px solid $LincolnGrayDark;
    padding: 0 15px;
    margin: 2em 0;

    .label  {
      @include font-size(1rem);
      margin-right: 10px;
      line-height: 3em;
    }

    select {
      border: none;
      @include font-size(1rem);
      line-height: 3em;
    }
  }

  .brandStoryBody {
    display: block;
    background: white;
    color: $LincolnGrayDark;
    border: 1px solid $LincolnGrayDark;
    padding: 10px 10px 0px;
    margin: 1em;
  }

  .cancel  {
    margin-left: 1em;
  }
</style>
