<template>
  <div class="whpcSubmission">
    <LincolnEffortlessExperienceLogoDark />
    <h2>Add Story</h2>

    <form @submit.prevent="submit">
      <TextBox :label="'Subject'"
               :name="'subject'"
               :required="true"
               v-model="whpcStory.subject"
               class="textBox" />

      <div class="storyBody">
        <RichTextBox @update="updateBody" :initialContent="whpcStory.body" />
      </div>
      <label class="status">
        <div class="label">Status</div>
        <div class="control">
          <select v-model="whpcStory.status">
            <option v-for="status in statuses" :value="status.value" :key="status.value">
              {{status.text}}
            </option>
          </select>
        </div>
      </label>
      <ImageCropperForStory class="imagePreviewer"
                            :storyData="whpcStory"
                            @updatedImage="updatedImage" />

      <ButtonSubmit
        :copy="'Save'"
        @callback="submit"
      />
      <ButtonCancel class="cancel" @click="closeModal" />

    </form>
  </div>
</template>

<script>
import RichTextBox from '../formElements/RichTextBox.vue';
import TextBox from '../formElements/TextBox.vue';
import LincolnEffortlessExperienceLogoDark from '../LincolnEffortlessExperienceLogoDark.vue';
import ImageCropperForStory from '../imageManipulation/ImageCropperForStory.vue';
import ButtonSubmit from '../formElements/buttons/buttonSubmit.vue';
import ButtonCancel from '../formElements/buttons/ButtonCancel.vue';

export default {
  name: 'WhpcSubmission',
  components: {
    RichTextBox,
    TextBox,
    LincolnEffortlessExperienceLogoDark,
    ImageCropperForStory,
    ButtonSubmit,
    ButtonCancel,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    story: {
      type: Object,
      required: true,
    },
    onWhpcStoryEdited: {
      type: Function,
      default: () => { },
    },
  },
  data() {
    return {
      whpcStory: this.story,
    };
  },
  computed: {
    statuses() {
      return this.$store.getters['whpcStoryStatusStore/statuses'];
    },
  },
  methods: {
    updateBody(html) {
      this.whpcStory.body = html;
    },
    async submit() {
      if (this.whpcStory.thumbnailAsset) {
        delete this.whpcStory.thumbnailAsset;
      }
      if (this.thumbnailAssetId) {
        this.whpcStory.thumbnailAssetId = this.thumbnailAssetId;
      }

      await this.$store.dispatch('adminStore/updateWhpcStory', this.whpcStory);
      this.onWhpcStoryEdited(this.whpcStory);
      this.closeModal();
    },
    closeModal() {
      this.$modal.hide(this.name);
    },
    async updatedImage(file) {
      const thumbnailImageUploaded = await this.$store.dispatch('assetStore/setAsset', file);
      this.thumbnailAssetId = thumbnailImageUploaded.id;
    },
  },
};
</script>

<style lang="scss" scoped>
  .whpcSubmission {
    padding: 2em;
    background-color: white;

    .status {
      display: flex;
      align-items: center;
      background: white;
      color: $LincolnGrayDark;
      border: 1px solid $LincolnGrayDark;
      padding: 0 15px;
      margin: 2em 0;

    .label {
      @include font-size(1rem);
        margin-right: 10px;
        line-height: 3em;
    }

    select {
      border: none;
      @include font-size(1rem);
      line-height: 3em;
    }
  }

  .storyBody {
    display: block;
    background: white;
    color: $LincolnGrayDark;
    border: 1px solid $LincolnGrayDark;
    padding: 10px 10px 0px;
    margin: 2em 0;
  }

  .imagePreviewer {
    display: block;
    background: white;
    color: $LincolnGrayDark;
    border: 1px solid $LincolnGrayDark;
    padding: 10px 10px 0px;
    margin: 2em 0;
  }

  }

  .options {
    color: $LincolnGrayLight;
  }

  .textBox {
    margin: 2em 0;
    display: block;
  }

  .cancel {
    margin-left: 1rem;
  }
</style>
