<template>
  <div class="brandStorySubmission">
    <LincolnEffortlessExperienceLogoDark />
    <h2>Brand Story Review</h2>

    <form @submit.prevent="submit">
      <TextBox :label="'Date Submitted'"
               :name="'submittedOn'"
               :disabled="true"
               v-model="submittedOn"
               class="textBox" />
      <TextBox :label="'Submitted By'"
               :name="'submittedBy'"
               :disabled="true"
               v-model="fullName"
               class="textBox" />
      <TextBox :label="'Subject'"
               :name="'subject'"
               :required="true"
               v-model="storyUnderReview.subject"
               class="textBox" />
      <label class="status">
        <div class="label">Status</div>
        <div class="control">
          <select v-model="storyUnderReview.status">
            <option v-for="status in statuses" :value="status.value" :key="status.value">
              {{status.text}}
            </option>
          </select>
        </div>
      </label>
      <div class="required">Fields marked with * are required.</div>
      <BusinessAreaOption v-for="(area) in this.businessAreas"
                          :key="area.id"
                          :label="area.name"
                          :name="businessAreasName"
                          :value="area.id"
                          :checked="isAreaSelected(area.id)"
                          @updateSelectedAreas="updateSelectedAreas" />
      <div class="required">Please select one.</div>
      <div class="tenet">
        Effortless Tenet
        <div class="arrowDown"></div>
      </div>
      <div class="tenetContainer">
        <EffortlessTenetOption v-for="(tenet) in this.tenets"
                               :key="tenet.id"
                               :label="tenet.name"
                               :name="effortlessTenetsName"
                               :value="tenet.id"
                               :checked="isTenetSelected(tenet.id)"
                               @updateSelectedTenets="updateSelectedTenets" />
      </div>

      <div class="brandStoryBody">
        <RichTextBox @update="updateBody" :initialContent="storyUnderReview.body" />
      </div>
      <ImageCropperForStory :storyData="story"
                            :isBrandStory="true"
                            @updatedImage="updatedImage" />
      <div class="existedAttachments" v-if="this.existedAttachments.length > 0">
        <div v-for="(file, key) in this.existedAttachments" :key="key" class="file-listing">
          <a href="#" @click="processFile(file)">
            {{ cleanFileName(file) }}
          </a>
          <span class="remove-file" @click="removeExistedAttachment(file.id)">Remove</span>
        </div>
      </div>
      <MultipleFiles v-model="newAttachments" :name="newAttachments" />
      <ButtonSubmit :copy="'Send Now'"
                    @callback="submit" />
      <ButtonCancel class="cancel" @click="closeModal" />
    </form>
  </div>
</template>

<script>
import RichTextBox from '../formElements/RichTextBox.vue';
import TextBox from '../formElements/TextBox.vue';
import BusinessAreaOption from '../stories/brandStories/BusinessAreaOption.vue';
import EffortlessTenetOption from '../stories/brandStories/EffortlessTenetOption.vue';
import LincolnEffortlessExperienceLogoDark from '../LincolnEffortlessExperienceLogoDark.vue';
import MultipleFiles from '../formElements/MultipleFiles.vue';
import ButtonSubmit from '../formElements/buttons/buttonSubmit.vue';
import ButtonCancel from '../formElements/buttons/ButtonCancel.vue';
import ImageCropperForStory from '../imageManipulation/ImageCropperForStory.vue';

export default {
  name: 'BrandStorySubmissionAdmin',
  components: {
    RichTextBox,
    TextBox,
    BusinessAreaOption,
    EffortlessTenetOption,
    LincolnEffortlessExperienceLogoDark,
    MultipleFiles,
    ImageCropperForStory,
    ButtonSubmit,
    ButtonCancel,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    story: {
      type: Object,
      required: true,
    },
    onBrandStoryEdited: {
      type: Function,
      default: () => { },
    },
  },
  data() {
    return {
      businessAreasName: 'businessAreas',
      effortlessTenetsName: 'effortlessTenets',
      storyUnderReview: this.story,
      existedAttachments: [],
      newAttachments: [],
      thumbnailAssetId: null,
    };
  },
  computed: {
    submittedOn() {
      return new Date(this.storyUnderReview.submittedOn).toLocaleDateString();
    },
    tenets() {
      return this.$store.getters['tenetStore/effortlessTenets'];
    },
    businessAreas() {
      return this.$store.getters['businessAreaStore/businessAreas'];
    },
    statuses() {
      return this.$store.getters['brandStoryStatusStore/statuses'];
    },
    fullName() {
      return `${this.storyUnderReview.submittedByUser.nameFirst} ${this.storyUnderReview.submittedByUser.nameLast}`;
    },
  },
  methods: {
    cleanFileName(fl) {
      const flName = fl.fileName.toString().replace(`_${fl.id}`, '');
      return flName;
    },
    updateBody(html) {
      this.storyUnderReview.body = html;
    },
    isTenetSelected(id) {
      return this.storyUnderReview.effortlessTenets.filter(tenet => tenet.id === id).length > 0;
    },
    isAreaSelected(id) {
      return this.storyUnderReview.businessAreas.filter(area => area.id === id).length > 0;
    },
    updateSelectedTenets(effortlessObject) {
      const indexOfTenet = this.storyUnderReview
        .effortlessTenets.map(tenet => tenet.id).indexOf(effortlessObject.id);

      if (indexOfTenet !== -1) {
        this.storyUnderReview
          .effortlessTenets.splice(indexOfTenet, 1);
      } else {
        this.storyUnderReview.effortlessTenets.push(effortlessObject);
      }
    },
    updateSelectedAreas(areaObject) {
      const indexOfArea = this.storyUnderReview
        .businessAreas.map(tenet => tenet.id).indexOf(areaObject.id);

      if (indexOfArea !== -1) {
        this.storyUnderReview
          .businessAreas.splice(indexOfArea, 1);
      } else {
        this.storyUnderReview.businessAreas.push(areaObject);
      }
    },
    deleteAsset(attachmentIndex) {
      if (this.newAttachments.length > 0) {
        this.newAttachments.splice(attachmentIndex, 1);
      }
    },
    async updatedImage(file) {
      const thumbnailImageUploaded = await this.$store.dispatch('assetStore/setAsset', file);
      this.thumbnailAssetId = thumbnailImageUploaded.id;
    },
    async loadAttachments() {
      const assetIds = this.story.attachments.map(asset => asset.attachmentAssetId);
      this.existedAttachments = [];
      if (assetIds.length > 0) {
        this.existedAttachments = await this.$store.dispatch('assetStore/getAssetList', assetIds);
      }
    },
    async loadBusinessAreas() {
      await this.$store.dispatch('businessAreaStore/getBusinessAreas');
    },
    async loadTenets() {
      await this.$store.dispatch('tenetStore/getEffortlessTenets');
    },
    async removeExistedAttachment(id) {
      const attachs = await this.$store.dispatch('brandStoryStore/removeAttachmentForBrandStory', { storyId: this.storyUnderReview.id, assetId: id });
      this.story.attachments = attachs;
      if (attachs.length === 0) {
        this.story.attachments = [];
      }
      await this.loadAttachments();
    },
    async processFile(assetFile) {
      const fileRes = await this.$store.dispatch(
        'assetStore/downloadAsset',
        assetFile.id,
      );
      const url = window.URL.createObjectURL(new Blob([fileRes.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', assetFile.fileName);
      document.body.appendChild(link);
      link.click();
    },
    async submit() {
      if (this.newAttachments.length > 0) {
        const formDataFile = new FormData();
        [...this.newAttachments].forEach((v) => {
          formDataFile.append('files', v);
        });
        const assetResp = await this.$store.dispatch('assetStore/setMultiAsset', formDataFile);
        if (assetResp != null) {
          [...assetResp].forEach((v) => {
            this.storyUnderReview.attachments.push({ attachmentassetid: v.id });
          });
        }
      }

      if (this.storyUnderReview.thumbnailAsset) {
        delete this.storyUnderReview.thumbnailAsset;
      }
      if (this.thumbnailAssetId) {
        this.storyUnderReview.thumbnailAssetId = this.thumbnailAssetId;
      }

      await this.$store.dispatch('adminStore/updateBrandStory', this.storyUnderReview);

      this.onBrandStoryEdited(this.storyUnderReview);
      this.$store.dispatch('buttonSubmitStore/done');
      this.closeModal();
    },
    closeModal() {
      this.$modal.hide(this.name);
    },
  },
  async mounted() {
    await this.loadAttachments();
    await this.loadBusinessAreas();
    await this.loadTenets();
  },
};
</script>

<style lang="scss" scoped>
  .brandStorySubmission {
    padding: 2em;
    background-color: white;

    .status {
      display: flex;
      align-items: center;
      background: white;
      color: $LincolnGrayDark;
      border: 1px solid $LincolnGrayDark;
      padding: 0 15px;
      margin: 2em 0;

      .label {
        @include font-size(1rem);
        margin-right: 10px;
        line-height: 3em;
      }

      select {
        border: none;
        @include font-size(1rem);
        line-height: 3em;
      }
    }

   .brandStoryBody {
      display: block;
      background: white;
      color: $LincolnGrayDark;
      border: 1px solid $LincolnGrayDark;
      padding: 10px 10px 0px;
      margin: 2em 0;
    }
  }

  .required {
    color: $LincolnWarn;
  }

  .options {
    color: $LincolnGrayLight;
  }

  .textBox {
    margin: 2em 0;
    display: block;
  }

  .tenet {
    background-color: $LincolnGrayLight;
    color: $LincolnGrayDark;
    width: 15%;
    text-transform: uppercase;
    padding: 1em;
    margin: 0.1em;
  }

  $arrowWidth: 0.7em;
  .arrowDown {
    display: inline-block;
    width: 0;
    height: 0;
    overflow: hidden;
    border-left: $arrowWidth solid transparent;
    border-right: $arrowWidth solid transparent;
    border-top: $arrowWidth solid $LincolnOrange;
  }

  .tenetContainer {
    display: inline-flex;
  }

  .cancel {
    margin-left: 1rem;
  }

  span.remove-file {
    color: red;
    cursor: pointer;
    float: right;
  }
</style>
