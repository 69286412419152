<template>
  <div class="admin">
    <div class="menu">
      <div class="menuItem"
           :class="{ selected: brandStoryAdmin }"
           @click="showBrandStoryAdmin">
        Brand Story Submissions
      </div>
      <div class="menuItem"
           :class="{ selected: commentsAdmin }"
           @click="showCommentsAdmin">
        Brand Story Comments
      </div>
      <div class="menuItem"
           :class="{ selected: whpcStoryAdmin }"
           @click="showWhpcStoryAdmin">
        Whpc Story Submissions
      </div>
      <div class="menuItem"
           :class="{ selected: profileImagesAdmin }"
           @click="showProfileImagesAdmin">
        Profile Images
      </div>
      <div class="button">
        <ButtonSubmit
          :copy="'Download User Report'"
          @callback="downloadUserReport"
          :allowSizing="true" />
      </div>
    </div>
    <div class="content">
      <BrandStoryAdmin v-show="brandStoryAdmin" />
      <CommentAdmin v-show="commentsAdmin" />
      <WhpcStoryAdmin v-show="whpcStoryAdmin" />
      <UserProfileImageAdmin v-show="profileImagesAdmin" />
    </div>
  </div>
</template>

<script>
import BrandStoryAdmin from '../components/admin/BrandStoryAdmin.vue';
import CommentAdmin from '../components/admin/CommentAdmin.vue';
import WhpcStoryAdmin from '../components/admin/WhpcStoryAdmin.vue';
import UserProfileImageAdmin from '../components/admin/UserProfileImageAdmin.vue';
import ButtonSubmit from '../components/formElements/buttons/buttonSubmit.vue';
import apiManager from '../api';

export default {
  name: 'Admin',
  components: {
    BrandStoryAdmin,
    CommentAdmin,
    WhpcStoryAdmin,
    UserProfileImageAdmin,
    ButtonSubmit,
  },
  data() {
    return {
      brandStoryAdmin: true,
      commentsAdmin: false,
      whpcStoryAdmin: false,
      profileImagesAdmin: false,
    };
  },
  methods: {
    showBrandStoryAdmin() {
      this.brandStoryAdmin = true;
      this.commentsAdmin = false;
      this.whpcStoryAdmin = false;
      this.profileImagesAdmin = false;
    },
    showCommentsAdmin() {
      this.brandStoryAdmin = false;
      this.commentsAdmin = true;
      this.whpcStoryAdmin = false;
      this.profileImagesAdmin = false;
    },
    showWhpcStoryAdmin() {
      this.brandStoryAdmin = false;
      this.commentsAdmin = false;
      this.whpcStoryAdmin = true;
      this.profileImagesAdmin = false;
    },
    showProfileImagesAdmin() {
      this.brandStoryAdmin = false;
      this.commentsAdmin = false;
      this.whpcStoryAdmin = false;
      this.profileImagesAdmin = true;
    },
    async downloadUserReport() {
      await apiManager.user.downloadUserReport();
      this.$store.dispatch('buttonSubmitStore/done');
    },
  },
  async created() {
    this.$analytics.trackPageView();
  },
};
</script>

<style lang="scss" scoped>
.admin {
  padding-bottom: 1em;
  display: flex;

  .menu {
    flex: 0 1 auto;
    min-width: 15%;
  }

  .menuItem {
    background-color: $LincolnOrange;
    color: white;
    padding: 1em;
  }

  .selected {
    background-color: white;
    color: $LincolnOrange;
  }

  .content {
    flex: 0 1 auto;
  }

  .button {
   margin: 1em;
  }
}
</style>
