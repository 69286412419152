<template>
  <div class="profileSubmission">
    <LincolnEffortlessExperienceLogoDark />
    <h2>Edit Profile</h2>

    <form @submit.prevent="submit">
      <label class="status">
        <div class="label">Name: </div>
        <div class="control">{{profile.nameLast}} {{profile.nameFirst}}</div>
      </label>
      <TextBox :label="'Preferred Name'"
               :name="'preferredName'"
               v-model="profile.namePreferred"
               class="textBox"
               :placeholder="'Preferred Name'" />
      <model-list-select :list="positions"
                         option-value="id"
                         option-text="name"
                         v-model="profile.position"
                         placeholder="Position" />
      <TextBox :label="'Email'"
               :name="'email'"
               :required="true"
               v-model="profile.email"
               class="textBox"
               :placeholder="'Email'" />
      <TextBox :label="'Phone'"
               :name="'phone'"
               :required="true"
               v-model="profile.phone"
               class="textBox"
               :placeholder="'Phone'" />
      <label class="status">
        <div class="label">Image Status</div>
        <div class="control">
          <select v-model="profile.profileImageStatus">
            <option v-for="status in statuses" :value="status.value" :key="status.value">
              {{status.text}}
            </option>
          </select>
        </div>
      </label>
      <ImageCropperForProfile class="imagePreviewer"
                              :profileData="profile"
                              @updatedImage="updatedImage" />
      <ButtonSubmit :copy="'Save'"
                    @callback="submit" />
      <ButtonCancel class="cancel" @click="closeModal" />

    </form>
  </div>
</template>

<script>
import { ModelListSelect } from 'vue-search-select';
import TextBox from '../formElements/TextBox.vue';
import LincolnEffortlessExperienceLogoDark from '../LincolnEffortlessExperienceLogoDark.vue';
import ImageCropperForProfile from '../imageManipulation/ImageCropperForProfile.vue';
import ButtonSubmit from '../formElements/buttons/buttonSubmit.vue';
import ButtonCancel from '../formElements/buttons/ButtonCancel.vue';

export default {
  name: 'ProfileSubmission',
  components: {
    TextBox,
    LincolnEffortlessExperienceLogoDark,
    ImageCropperForProfile,
    ButtonSubmit,
    ModelListSelect,
    ButtonCancel,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    profile: {
      type: Object,
      required: true,
    },
    onUserProfileEdited: {
      type: Function,
      default: () => { },
    },
    options: Array,
  },
  computed: {
    statuses() {
      return this.$store.getters['userProfileImageStatusStore/statuses'];
    },
    positions() {
      return this.$store.getters['positionStore/positions'];
    },
  },
  methods: {
    async submit() {
      await this.$store.dispatch('adminStore/updateUserProfile', this.profile);
      this.onUserProfileEdited(this.profile);
      this.closeModal();
    },
    closeModal() {
      this.$modal.hide(this.name);
    },
    async updatedImage(file) {
      const thumbnailImageUploaded = await this.$store.dispatch('assetStore/setAsset', file);
      this.profile.thumbnailAssetId = thumbnailImageUploaded.id;
      this.profile.thumbnailAsset = thumbnailImageUploaded;
    },
  },
};
</script>

<style lang="scss" scoped>
  .profileSubmission {
    padding: 2em;
    background-color: white;
    .status  {
      display: flex;
      align-items: center;
      background: white;
      color: $LincolnGrayDark;
      border: 1px solid $LincolnGrayDark;
      padding: 0 15px;
      margin: 2em 0;
      .label {
        @include font-size(1rem);
        margin-right: 10px;
        line-height: 3em;
      }
      select {
        border: none;
        @include font-size(1rem);
        line-height: 3em;
      }
    }
    .storyBody {
      display: block;
      background: white;
      color: $LincolnGrayDark;
      border: 1px solid $LincolnGrayDark;
      padding: 10px 10px 0px;
      margin: 2em 0;
    }
    .imagePreviewer {
      display: block;
      background: white;
      color: $LincolnGrayDark;
      border: 1px solid $LincolnGrayDark;
      padding: 10px 10px 0px;
      margin: 2em 0;
    }

  }

  .options {
    color: $LincolnGrayLight;
  }

  .textBox {
    margin: 2em 0;
    display: block;
  }

  .cancel {
    margin-left: 1rem;
  }
</style>
