<template>
  <div>
    <vue-good-table :columns="columns"
                    :rows="rows"
                    :pagination-options="paginationOptions"
                    :sort-options="{
                      enabled: true,
                      initialSortBy: [{
                        field: 'submittedOn',
                        type: 'desc'
                      },{
                        field: 'id',
                        type: 'desc'
                      }]
                    } " @on-page-change="onPageChange">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'edit'">
          <button @click="editUserProfileImage(props.row.id)">edit</button>
        </span>
        <span v-else-if="props.column.field == 'profileImage'">
          <UserImageThumbnail :thumbnailAssetId="props.row.thumbnailAssetId"/>
        </span>
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table';
import ProfileImageSubmissionAdmin from './ProfileImageSubmissionAdmin.vue';
import UserImageThumbnail from '../profile/UserImageThumbnail.vue';

export default {
  name: 'UserProfileImageAdmin',
  components: {
    VueGoodTable,
    UserImageThumbnail,
  },
  data() {
    return {
      rows: [],
      pgOptions: { enabled: true, setCurrentPage: 1 },
      currentPage: 1,
    };
  },
  computed: {
    paginationOptions() {
      return this.pgOptions;
    },
    statuses() {
      return this.$store.getters['userProfileImageStatusStore/statuses'];
    },
    columns() {
      return [
        {
          label: 'Id',
          field: 'id',
          hidden: true,
        },
        {
          label: 'User',
          field: 'submittedBy',
          sortable: true,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Submitted On',
          field: 'submittedOn',
          sortable: true,
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'yyyy-MM-dd',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Reviewed By',
          field: 'reviewedBy',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Profile Image',
          field: 'profileImage',
        },
        {
          label: 'Status',
          field: 'status',
          sortable: true,
          filterOptions: {
            enabled: true,
            filterDropdownItems: this.statuses.map(status => status.text),
          },
        },
        {
          label: 'Edit',
          field: 'edit',
        },
      ];
    },
  },
  methods: {
    onPageChange(params) {
      this.currentPage = params.currentPage;
    },
    async getUserProfileImages() {
      const response = await this.$store.dispatch('adminStore/getProfiles');
      this.rows = response.map(this.toRowObject);
    },
    onUserProfileEdited(response) {
      const toUpdate = this.rows.find(r => r.id === response.id);
      toUpdate.thumbnailAssetId = response.thumbnailAssetId;
      this.pgOptions.setCurrentPage = this.currentPage;
    },
    toRowObject(userProfile) {
      const toDateString = (date) => {
        const pad = number => (number.length === 1
          ? `0${number}`
          : number);

        const temp = new Date(date);

        return `${temp.getFullYear()}-${pad(temp.getMonth() + 1)}-${pad(temp.getDate())}`;
      };
      const returnUser = (approvedUser) => {
        if (approvedUser) {
          return approvedUser.namePreferred;
        }
        return null;
      };
      return {
        id: userProfile.id,
        submittedOn: userProfile.thumbnailAsset
          ? toDateString(userProfile.thumbnailAsset.createdOn) : null,
        submittedBy: userProfile.namePreferred,
        reviewedBy: userProfile.thumbnailAsset
          ? returnUser(userProfile.thumbnailAsset.approvedByUser) : null,
        status: this.statuses.filter(
          status => status.value === userProfile.profileImageStatus,
        )[0].text,
        thumbnailAssetId: userProfile.thumbnailAsset ? userProfile.thumbnailAsset.id : null,
      };
    },
    async editUserProfileImage(id) {
      const name = 'ProfileImageSubmissionAdmin';

      const response = await this.$store.dispatch('adminStore/getProfile', id);
      this.$modal.show(ProfileImageSubmissionAdmin, {
        profile: response,
        name,
        onUserProfileEdited: this.onUserProfileEdited,
      },
      {
        name,
        adaptive: true,
        height: 'auto',
        width: '70%',
        scrollable: true,
      });
    },
  },
  async mounted() {
    await this.getUserProfileImages();
  },
  async created() {
    await this.$store.dispatch('userProfileImageStatusStore/getProfileImageStatuses');
    await this.$store.dispatch('positionStore/getPositions');
  },
};
</script>
