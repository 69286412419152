<template>
  <div>
    <vue-good-table :columns="columns"
                    :rows="rows"
                    :pagination-options="{ enabled: true }"
                    :sort-options="{
                      enabled: true,
                      initialSortBy: [{
                        field: 'submittedOn',
                        type: 'desc'
                      },{
                        field: 'id',
                        type: 'desc'
                      }]
                    } ">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'edit'">
          <button @click="editComment(props.row.id)">edit</button>
        </span>
        <span v-else-if="props.column.field == 'brandStoryTitle'">
          <router-link class="link"
                       :to="'/brandStory?id=' + props.row.brandStoryId">
            {{props.formattedRow[props.column.field]}}
          </router-link>
        </span>
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table';
import CommentSubmissionAdmin from './CommentSubmissionAdmin.vue';

export default {
  name: 'CommentAdmin',
  components: {
    VueGoodTable,
  },
  data() {
    return {
      rows: [],
    };
  },
  computed: {
    statuses() {
      return this.$store.getters['commentStatusStore/statuses'];
    },
    columns() {
      return [
        {
          label: 'Id',
          field: 'id',
          hidden: true,
        },
        {
          label: 'rawDate',
          field: 'rawDate',
          hidden: true,
        },
        {
          label: 'Date Submitted',
          field: 'submittedOn',
          sortable: true,
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'yyyy-MM-dd',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Submitted By',
          field: 'submittedBy',
          sortable: true,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Reviewed By',
          field: 'reviewedBy',
          sortable: true,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Brand Story',
          field: 'brandStoryTitle',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Text',
          field: 'text',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Status',
          field: 'commentStatus',
          sortable: true,
          filterOptions: {
            enabled: true,
            filterDropdownItems: this.statuses.map(status => status.text),
          },
        },
        {
          label: 'Edit',
          field: 'edit',
        },
      ];
    },
  },
  methods: {
    async getComments() {
      const response = await this.$store.dispatch('adminStore/getComments');

      this.rows = response.map(this.toRowObject);
    },
    onCommentEdited(comment) {
      this.rows = this.rows.filter(row => row.id !== comment.id);
      this.rows.push(this.toRowObject(comment));
    },
    toRowObject(comment) {
      const toDateString = (date) => {
        const pad = number => (number.length === 1
          ? `0${number}`
          : number);

        const temp = new Date(date);

        return `${temp.getFullYear()}-${pad(temp.getMonth() + 1)}-${pad(temp.getDate())}`;
      };

      return {
        id: comment.id,
        submittedOn: toDateString(comment.submittedOn),
        submittedBy: comment.user ? comment.user.namePreferred : null,
        reviewedBy: comment.approvedByUser ? comment.approvedByUser.namePreferred : null,
        brandStoryTitle: comment.brandStory.subject,
        text: comment.text,
        brandStoryId: comment.brandStory.id,
        commentStatus: this.statuses.filter(status => status.value === comment.status)[0].text,
      };
    },
    async editComment(id) {
      const name = 'CommentSubmissionAdmin';

      const response = await this.$store.dispatch('adminStore/getComment', id);
      this.$modal.show(CommentSubmissionAdmin, {
        comment: response,
        name,
        onCommentEdited: this.onCommentEdited,
      },
      {
        name,
        adaptive: true,
        height: 'auto',
        width: '70%',
        scrollable: true,
      });
    },
  },
  async mounted() {
    await this.getComments();
  },
  async created() {
    await this.$store.dispatch('commentStatusStore/getCommentStatuses');
  },
};
</script>
